import {
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import StreetviewIcon from "@material-ui/icons/Streetview";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import RowingIcon from "@material-ui/icons/Rowing";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BoatInfoForm from "./BoatInfoForm";
import FeaturesSelection from "../feature/FeaturesSelection";
import BoatPrices from "./BoatPrices";
import { useBoatContext } from "providers/BoatProvider";
import BoatPackages from "./BoatPackages";
import { useGetAllFeatures } from "@actions/featureActions";
import {
  useGetAllBoatPageLayouts,
  useGetAllOwners,
  useUpdateBoat,
} from "@actions/boatActions";
import Steps from "@components/Steps";
import NavigateNext from "@material-ui/icons/NavigateNext";
import BoatTypes from "./BoatTypes";
import BoatLocations from "./BoatLocations";
import { BoatCard, ImageGrid } from "components-library";
import BoatAssets from "./BoatAssets";
import WharfSelection from "@components/wharf/WharfSelection";
import WysiwygEditor from "@components/WysiwygEditor/WysiwygEditor";
import useBoatOrderedImages from "components-library/lib/hooks/useBoatOrderedImages";
import BoatCommision from "./BoatCommision";
import BoatAttachments from "./BoatAttachments";
import { Gavel } from "@material-ui/icons";
import BoatWaivers from "./BoatWaivers";

const useStyles = makeStyles(({ breakpoints }) => ({
  preview: {
    maxWidth: breakpoints.values.lg / 3,
  },
  stick: {
    position: "sticky",
    top: 0,
  },
}));

const BoatWizard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { boat, setBoat } = useBoatContext();
  const [updateBoat, { loading }] = useUpdateBoat();
  const { loading: featuresLoading, data: featuresData } = useGetAllFeatures();
  const { preview, stick } = useStyles();
  const { images: boatImages } = useBoatOrderedImages(boat);
  const { data: ownersData } = useGetAllOwners();
  const { data: layoutData } = useGetAllBoatPageLayouts();

  const handleUpdateBoat = useCallback(
    (boat) => {
      let mediaOrder = boat.mediaOrder || [];
      const notInOrder = boat.medias
        .map((m) => m.id)
        .filter((id) => !mediaOrder.includes(id));
      mediaOrder = [...mediaOrder, ...notInOrder];

      let input = {
        name: boat.name,
        description: boat.description,
        oneLiner: boat.oneLiner,
        cardExtraOne: boat.cardExtraOne,
        cardExtraTwo: boat.cardExtraTwo,
        capacity: boat.capacity,
        minimumHire: boat.minimumHire,
        additionalOptions: boat.additionalOptions,
        videoLink: boat.videoLink,
        attachmentsInfo: boat.attachmentsInfo,
        googleCalendar: boat.googleCalendar,
        googleCalendarWrite: boat.googleCalendarWrite,
        archived: boat.archived || false,
        showContact: boat.showContact || false,
        skipperRequired: boat.skipperRequired || false,
        features: boat.features.map((f) => ({ id: f.id, name: f.name })),
        boatTypes: boat.boatTypes.map((bt) => ({ id: bt.id })),
        medias: boat.medias.map((m) => ({
          id: m.id,
          name: m.name,
          source: "",
        })),
        attachments: (boat.attachments || []).map((m) => ({
          id: m.id,
          name: m.name,
          source: "",
        })),
        comms: boat.comms.map((com) => ({
          type: com.type,
          commission:
            com.commission && com.commission !== ""
              ? com.commission
              : undefined,
          defaultQuoteRate: com.defaultQuoteRate,
        })),
        mediaOrder,
        wharfs: boat.wharfs.map((w) => ({ id: w.id })),
        locations: boat.locations.map((l) => ({ id: l.id })),
      };

      if (boat.owner) {
        input.ownerId = boat.owner.id;
      }

      if (boat.boatPageLayout) {
        input.layoutId = boat.boatPageLayout.id;
      }

      if (boat.boatPrice) {
        input.boatPriceId = boat.boatPrice.id;
      }

      return updateBoat({
        variables: {
          id: boat.id,
          input,
        },
      });
    },
    [updateBoat]
  );

  useEffect(() => {
    setSteps([
      {
        label: "Boat Info",
        component: (
          <BoatInfoForm
            owners={ownersData?.owners || []}
            layouts={layoutData?.boatPageLayouts || []}
          />
        ),
        icon: <ImportContactsIcon fontSize="inherit" />,
      },
      {
        label: "Boat Types",
        component: <BoatTypes parent={boat} setParent={setBoat} />,
        icon: <RowingIcon fontSize="inherit" />,
      },
      {
        label: "Features",
        component: featuresLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <FeaturesSelection
            features={featuresData ? featuresData.features : []}
            parent={boat}
            setParent={setBoat}
            title="Select which features this boat has from the below"
          />
        ),
        icon: <OutdoorGrillIcon fontSize="inherit" />,
      },
      {
        label: "Locations",
        component: <BoatLocations />,
        icon: <LocationOnIcon fontSize="inherit" />,
      },
      {
        label: "Wharfs",
        component: (
          <WharfSelection
            title="Select wharfs this boat can be picked up from and dropped off at"
            parent={boat}
            setParent={setBoat}
          />
        ),
        icon: <StreetviewIcon fontSize="inherit" />,
      },
      {
        label: "Assets",
        component: <BoatAssets updateBoat={handleUpdateBoat} />,
        icon: <PhotoLibraryIcon fontSize="inherit" />,
      },
      {
        label: "Attachments",
        component: <BoatAttachments updateBoat={handleUpdateBoat} />,
        icon: <PictureAsPdfIcon fontSize="inherit" />,
      },
      {
        label: "Waivers",
        component: <BoatWaivers boat={boat} />,
        icon: <Gavel fontSize="inherit" />,
      },
      {
        label: "Rates",
        component: <BoatPrices updateBoat={handleUpdateBoat} />,
        icon: <AttachMoneyIcon fontSize="inherit" />,
      },
      {
        label: "Packages",
        component: <BoatPackages />,
        icon: <CardGiftcardIcon fontSize="inherit" />,
      },
      {
        label: "Other Info",
        component: (
          <WysiwygEditor
            msg={boat?.additionalOptions}
            setMsg={(additionalOptions) => {
              setBoat({
                ...boat,
                additionalOptions,
              });
            }}
          />
        ),
        icon: <LineStyleIcon fontSize="inherit" />,
      },
      {
        label: "Commision",
        component: <BoatCommision />,
        icon: <AccountBalanceIcon fontSize="inherit" />,
      },
      // {
      //   label: "Deploy",
      //   component: <div>todo</div>,
      //   icon: <HelpOutlineIcon fontSize="inherit" />,
      // },
    ]);
  }, [
    boat,
    setBoat,
    featuresLoading,
    featuresData,
    handleUpdateBoat,
    ownersData,
  ]);

  const handleSave = () => {
    handleUpdateBoat(boat).then(() => {
      handleStep(activeStep + 1);
    });
  };

  const handleStep = (i) => {
    setActiveStep(i);
  };

  // todo, base this on mimnimum setup
  const isStepComplete = (i) => {
    return i < activeStep;
  };

  return (
    <Grid container spacing={5}>
      <Grid item lg={8} md={12}>
        <Paper>
          <Steps
            steps={steps}
            activeStep={activeStep}
            isStepComplete={isStepComplete}
            handleStep={handleStep}
            next={
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                endIcon={
                  loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <NavigateNext />
                  )
                }
                onClick={handleSave}
              >
                Save & next
              </Button>
            }
          />
        </Paper>
      </Grid>
      <Grid className={preview} item lg={4} md={false}>
        <Grid container spacing={5} className={stick}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Boat Card preview
            </Typography>
            <BoatCard
              boat={boat}
              useNoneGatsbyImage
              onClick={() => alert(`Go to ${boat.name}`)}
              LinkComponent={(props) => (
                <Link {...props} to="">
                  {props.children}
                </Link>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Boat image preview
            </Typography>
            <ImageGrid images={boatImages} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoatWizard;
